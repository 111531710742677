#homeContainer {
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;

	.showcaseText {
		// font-family: monospace;
		font-size: 8rem;
		font-weight: bold;
		color: rgb(219, 221, 105);
		line-height: 0.9;
		text-shadow: 2px 5px 10px #860909;
	}

	#danceContainer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		#latestIssueHome {
			align-self: center;
			height: 25px;
			text-decoration: none;
			margin-top: 25%;
			margin-bottom: none;
			// margin: 30px 10px 30px 10px;
			color: white !important;
			text-decoration: none;
			text-transform: lowercase;
			text-align: center;
			background: rgb(212, 42, 42);
			font-size: 1.1rem;
			padding: 30px;
			border: none;
			display: inline-block;
			transition: all 0.4s ease 0s;
			z-index: 5;

			&:hover {
				color: #ffffff !important;
				background: #f5b427;
				border-color: #f6b93b !important;
				transition: all 0.4s ease 0s;
				-webkit-box-shadow: 0px 5px 40px -10px #3bcaf6;
				-moz-box-shadow: 0px 5px 40px -10px #3bcaf6;
				transition: all 0.3s ease 0s;
				padding-left: 35px;
				padding-right: 35px;
			}
		}

		#clicc {
			pointer-events: none;
			z-index: 3;
			margin-top: none;
			transform: translate(-17%, -5%);
		}
	}
	#mobileContainerHome {
		.danceDolphin2 {
			display: none;
			pointer-events: none;
		}
	}
}

@media (max-width: 760px) {
	#homeContainer {
		.showcaseText {
			font-size: 6rem;
		}
	}
}

@media (max-width: 600px) {
	#homeContainer {
		.showcaseText {
			font-size: 5rem;
		}
		#danceContainer {
			img {
				max-height: 300px;
			}
		}
	}
}

@media (max-width: 560px) {
	#homeContainer {
		.showcaseText {
			font-size: 5rem;
		}
		#danceContainer {
			img {
				max-height: 200px;
			}
		}
	}
}

@media (max-width: 500px) {
	#homeContainer {
		.showcaseText {
			font-size: 4.5rem;
		}

		#danceContainer {
			.danceDolphin1 {
				display: none;
			}
		}
		#mobileContainerHome {
			display: flex;
			flex-direction: row;
			height: auto;
			justify-content: space-around;
			.danceDolphin2 {
				display: block;
				position: absolute;
				bottom: 0;
				max-height: 300px;
				overflow: hidden;
			}
			#dd1 {
				left: 50px;
			}
			#dd2 {
				right: 50px;
			}
		}
	}
}

@media (max-width: 430px) {
	#homeContainer {
		.showcaseText {
			font-size: 3.5rem;
		}
	}
}

@media (max-width: 340px) {
	#homeContainer {
		.showcaseText {
			font-size: 2.5rem;
		}
		#danceContainer {
			#latestIssueHome {
				// width: 150px;
				font-size: 0.8rem;
				height: 15px;
				margin-left: none;
				margin-right: none;
				padding-left: none !important;
				padding-right: none !important;
			}
		}
	}
}

@media (max-height: 740px) {
	#mobileContainerHome {
		.danceDolphin2 {
			// height: 85px;
			opacity: 0.5;
		}
	}
}
@media (max-width: 500px) {
	#mobileContainerHome {
		.danceDolphin2 {
			// height: 85px;
			opacity: 0.5;
		}
	}
}

@media (max-height: 640px) {
	#mobileContainerHome {
		.danceDolphin2 {
			height: 200px;
		}
	}
}
