#fadeCover {
	position: fixed;
	left: 0;
	top: 0px;
	height: 150vh;
	width: 150vw;
	background: rgba(25, 25, 25, 1);
	z-index: 99;
	display: none;
}

.navbar {
	text-align: center;
	margin: 30px 0 0 0;
	z-index: 3;

	#menu {
		display: none;
	}

	& .title {
		margin: 30px 0px 30px 0px;
	}

	& .links {
		// box-sizing: border-box;
		// -moz-box-sizing: border-box;
		display: inline-block;
		// width: 10%;
		text-decoration: none;
		margin: 0px 10px 30px 10px;
		color: rgb(255, 255, 255) !important;
		background: none;
		background-size: contain;
		font-size: 1rem;
		text-decoration: none;
		text-transform: lowercase;
		padding: 20px;
		display: inline-block;
		transition: all 0.4s ease 0s;

		&:hover {
			color: #ffffff !important;
			background: #f5b427;
			transition: all 0.4s ease 0s;
			-webkit-box-shadow: 0px 5px 40px -10px #3bcaf6;
			-moz-box-shadow: 0px 5px 40px -10px #3bcaf6;
			transition: all 0.3s ease 0s;
		}
	}
}

.latest {
	top: 0;
	font-size: 0.9rem;
	padding: 0;
	text-align: center;
	background: none;
	color: red;
	z-index: 3;
}

#navContainer {
	display: flex;
	flex-direction: column;
}

@media (max-width: 700px) {
	.navbar {
		& .links {
			font-size: 0.8rem;
		}
		& img {
			display: none;
		}
	}
}

@media (max-width: 620px) {
	.navbar {
		& .links {
			font-size: 0.8rem;
			padding: 20px 10px 20px 10px;
			margin: none;

			&:hover {
				// background: none;
				// -webkit-box-shadow: none;
				// -moz-box-shadow: none;
			}
		}
	}
}

@media (max-width: 500px) {
	.navbar {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		#menu {
			display: flex;
			width: 80px;
			opacity: 0.65;
			align-self: center;
			cursor: pointer;
			margin-left: auto;
			z-index: 100;
			transform: translateY(-20px);
			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			transition-property: transform;
			transition: all 0.6s ease 0s;
		}

		& .links {
			z-index: 100;
			align-self: center;
			display: none;
			font-size: 0.9rem;
			padding: 20px;
			margin: none;
			margin-top: 25px;
			position: relative;
			// background: rgba(116, 6, 6, 0.5);

			// transition: display 1s;
			// margin-left: 50vw;
			// margin-right: 50vw;
		}
	}
}

@media (max-height: 700px) {
	.navbar {
		& .links {
			margin-top: 10px;
		}
	}
}

@media (max-height: 600px) {
	.navbar {
		& .links {
			margin-top: 0px;
		}
	}
}

@media (max-height: 550px) {
	.navbar {
		& .links {
			margin-top: 0px;
			padding: 7px;
		}
	}
}
