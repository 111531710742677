#galleryContainer {
  position: relative;
  // left: 0;
  margin: 10px auto 10px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  // max-width: 80vw;

  .artpiece {
    display: flex;
    flex-direction: column;
    margin: 0 auto 50px auto;
    background: rgba(119, 23, 230, 0.5);
    width: 500px;
    border-radius: 25px;
    padding-bottom: 45px;
    // height: 700px;
    // -webkit-box-shadow: 0px 5px 100px -10px #f6bb3b;
    // -moz-box-shadow: 0px 5px 60px -10px #f6bb3b;

    .issuanceText {
      border: 2px solid white;
      border-radius: 25px;
      width: 95px;
      padding: 10px;
      align-self: center;
      background: rgb(119, 23, 230);
      transform: translateY(-25px);
    }

    .artText {
      text-transform: uppercase;
      padding: 10px;
      font-size: 1.5rem;
      color: rgb(219, 221, 105);
      text-shadow: 2px 5px 10px #860909;
    }

    img {
      align-self: center;
      margin: 30px 0 0px 0;
      max-height: 45vh;
      max-width: 90%;
      // -webkit-box-shadow: 0px 5px 60px -10px #3bcaf6;
      // -moz-box-shadow: 0px 5px 60px -10px #3bcaf6;
      // z-index: 3 !important;
    }
    .artShowcase {
      display: none;
      margin: 0 auto 10px auto;
      // position: absolute;
      content: "test'";
      background-color: #1d677e;
      opacity: 0.7;
      height: 20vh;
      width: 40%;
      // align-self: center;
      z-index: 1 !important;
      border-radius: 0 0 15px 15px;
      transform: translateY(-1px);
    }
  }
}

.nftButton {
  display: inline-block;
  transform: translateY(55px);
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
  padding: 1em 1.5em;
  background: gold;
  width: 150px;
  border-radius: 25px;
  margin: -25px auto 45px auto;
  transition: all 0.4s ease 0s;
  border: none;
  color: black;
  font-family: monospace;
  text-decoration: none;
  -webkit-box-shadow: 0px 5px 40px -10px #f63b3b;
  -moz-box-shadow: 0px 5px 40px -10px #f63b3b;

  &:hover {
    color: white !important;
    background: purple;
    border-color: #f6b93b !important;
    transition: all 0.4s ease 0s;
    -webkit-box-shadow: 0px 10px 40px -10px #f63b3b;
    -moz-box-shadow: 0px 10px 40px -10px #f63b3b;
    width: 200px;
    // padding: 1.5em;
    border-radius: 0 0 0 0;
  }
}

@media (max-width: 520px) {
  #galleryContainer {
    .artpiece {
      width: 90%;
    }
  }
}
