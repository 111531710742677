@import "./components/Navbar.scss";
@import "./components/pages/Home.scss";

* {
	color: white;
	// font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	// 	Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	// font-family: "Open Sans", sans-serif;
	font-family: "Major Mono Display", monospace;
	text-transform: capitalize;
	font-style: bold;
	font-weight: 800;

	#h {
		text-transform: lowercase !important;
	}
	h1 {
		text-shadow: 2px 5px 10px #860909;
		text-transform: uppercase;
	}
}

body::-webkit-scrollbar {
	width: 5px;
	&::before {
		content: "hello";
		width: 15px;
		height: 15px;
	}
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: purple;
	// outline: 1px solid purple;
}

html {
	background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)),
		url("../img/grid.jpg");
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100vw !important;
}

body {
	overflow-y: overlay;
	overflow-x: hidden;
}

/* Fading effect on page transition */

// articles
.articleTextContainer {
	z-index: 1;
	display: flex;
	flex-direction: column;
	div {
		font-family: "Source Serif Pro", serif;
		font-size: 1.3rem;
		text-transform: none;
		margin-bottom: 25px;
		line-height: 1.3;
	}
	img {
		max-width: 90%;
		max-height: 50vh;
		margin: 10px auto 25px auto;
		align-self: center;
		-webkit-box-shadow: 0px 0px 80px 10px #3bcaf6;
		-moz-box-shadow: 0px 0px 80px 10px #3bcaf6;
		z-index: 0;
	}
	max-width: 750px;
	margin: 35px auto 35px auto;
	padding: 0 15px 0 15px;
	text-align: left;
	align-self: center;
	// border: solid red 5px;
}

.leadLetter {
	font-size: 2.4rem;
}

.articleTitle {
	text-transform: uppercase !important;
	max-width: 350px;
	margin: 0 auto 25px auto;
	color: rgb(219, 221, 105);
	// line-height: 0.9;
	text-shadow: 2px 5px 10px #860909;
}

#specialText1 {
	text-align: center !important;
	max-width: 400px;
	margin: 0 auto 25px auto;
}

.textLink {
	text-transform: none;
	font-family: "Source Serif Pro", serif;
	color: rgb(231, 140, 231);
	text-decoration: none;
}

.articleLine {
	display: block;
	height: 1px;
	width: 50px;
	border: 0;
	border-top: 3px solid #ccc;
	margin: 2em auto;
	padding: 0;
}

.raribleButtonArticle {
	display: block;
	text-transform: uppercase;
	font-size: 1.4rem;
	text-align: center;
	padding: 1em 1.5em;
	background: gold;
	z-index: 5;
	width: 150px;
	border-radius: 0 0 25px 25px;
	margin: -25px auto 45px auto;
	transition: all 0.4s ease 0s;
	border: none;
	color: black;
	font-family: monospace;
	text-decoration: none;
	-webkit-box-shadow: 0px 5px 40px -10px #f63b3b;
	-moz-box-shadow: 0px 5px 40px -10px #f63b3b;

	&:hover {
		color: white !important;
		background: purple;
		border-color: #f6b93b !important;
		transition: all 0.4s ease 0s;
		-webkit-box-shadow: 0px 10px 40px -10px #f63b3b;
		-moz-box-shadow: 0px 10px 40px -10px #f63b3b;
		width: 200px;
		// padding: 1.5em;
		border-radius: 0 0 0 0;
	}
}

@media (max-width: 680px) {
	.articleTextContainer {
		div {
			font-size: 1.1rem;
		}
	}
	.leadLetter {
		font-size: 2rem;
	}
}

// end articles

.page {
	padding-top: 125px;
	background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)),
		url("../img/grid.jpg");
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	// border: 5px red solid;
}

.fade-appear .fade-enter {
	opacity: 0;
	z-index: 1;
}
.fade-appear-active .fade.enter.fade-enter-active {
	opacity: 1;
	transition: opacity 600ms linear 300ms;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 600ms linear;
}

/*  */

@media (max-width: 500px) {
	.page {
		padding-top: 80px;
	}
}
