.issueBox {
  width: 550px;
  height: 35vh;
  // border: 5px red solid;
  margin-top: 45px;
  display: flex;
  flex-direction: column;

  & img {
    align-self: center;
    max-width: 80%;
    // height: auto;
    // min-height: 0;
    // object-fit: contain;
    // -webkit-box-shadow: 0px 10px 40px -10px #e6c927;
    // -moz-box-shadow: 0px 10px 40px -10px #e6c927;
  }

  & > div {
    background: white;
    margin: 0 auto 0 auto;
    padding: 20px;
    border: 2px solid white;
    margin-top: auto;
    text-transform: lowercase;
    transform: translate(100px, -100px);
    transition: all 0.4s ease 0s;
    // transform: translateX(50px);
    & div {
      color: black;
    }
    &:hover {
      padding: 30px;
      border: 3px black solid;
    }
  }
}

#issuesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.articleBox {
  background: rgba(23, 230, 230, 0.5);
  border-radius: 5px;
  // height: 10vh;
  border: 1px solid gray;
  margin: 10px auto 30px auto;
  width: 700px;
  max-width: 80vw;
  overflow: hidden;
  text-transform: lowercase;
  padding-bottom: 15px;
  word-wrap: break-word;
  text-decoration: none;
  transition: all 0.4s ease 0s;

  &:hover {
    background: purple;
  }
}

.articleContainer {
  display: flex;
  flex-direction: column;
}

.listTitle {
  padding: 15px 0 15px 0;
  font-size: 1.5rem;
  text-transform: uppercase !important;
  max-width: 350px;
  margin: 0 auto 25px auto;
  color: rgb(219, 221, 105);
  // line-height: 0.9;
  text-shadow: 2px 5px 10px #860909;
  text-decoration: none;
}

#issue2 {
  transform: translateY(-40px);
  & img {
    height: 100%;
  }
}

#issue3 {
  & img {
    // height: 100%;
    min-height: 30vh;
    margin-bottom: none;
  }
}

#issue4 {
  & img {
    min-height: 30vh;
  }
}

#hzScroll::-webkit-scrollbar {
  height: 5px;
  // &::before {
  // 	content: "hello";
  // 	width: 15px;
  // 	height: 15px;
  // }
}

#hzScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

#hzScroll::-webkit-scrollbar-thumb {
  background-color: rgb(0, 128, 0);
  // outline: 1px solid purple;
}

@media (max-width: 500px) {
  .issueBox {
    width: 450px;
    & > div {
      div {
        font-size: 0.8rem;
      }
    }
  }
  #issue1 {
    max-width: 400px;
  }
}
