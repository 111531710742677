#praxisContainer {
	background: none;
	width: 80%;
	margin: 0 auto 0 auto;

	.praxisLine {
		display: block;
		height: 1px;
		width: 50px;
		border: 0;
		border-top: 3px solid #ccc;
		margin: 1em auto;
		padding: 0;
	}

	#dolphinGirl {
		width: 100%;
		max-width: 80vw;
		-webkit-box-shadow: 0px 0px 80px 10px #3bcaf6;
		-moz-box-shadow: 0px 0px 80px 10px #3bcaf6;
	}

	p {
		text-transform: lowercase;
	}
	img {
	}
}
